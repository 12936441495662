@import "../variables";

#slide-photo-3 {
}

#slide-photo-3.slide-content {
  padding: 0 0 40px 0;
}

#slide-photo-3 p {
  padding: 15px 30px;
  text-align: center;
}