@import "../variables";

#slide-ai-modules {
  padding-top: 40px;
  padding-bottom: 140px
}

#slide-ai-modules p {
  text-align: justify;
}

#slide-ai-modules h2 {
  font-size: 30px;
  color: #CE00FF;
}