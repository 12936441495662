@import "../variables";

#slide-video-1 {
  text-align: center;
}

#slide-video-1 p {
  font-size: 15px;
  padding-left: 40px;
  padding-right: 40px;
}

#slide-video-1.slide-content {
  padding: 0;
}