@import "../variables";

#slide-video-2 {
  text-align: center;
}

#slide-video-2 p {
  font-size: 15px;
}

#slide-video-2.slide-content {
  padding: 0;
}