@import "../variables";

#slide-centre-pompidou {
  padding-top: 40px;
  padding-bottom: 140px
}

#slide-centre-pompidou p {
  text-align: justify;
}

#slide-centre-pompidou h2 {
  font-size: 30px;
  color: #CE00FF;
}