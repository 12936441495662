@import "../variables";

#slide-photo-2 {
}

#slide-photo-2.slide-content {
  padding: 0 0 40px 0;
}

#slide-photo-2 p {
  padding: 15px 30px;
  text-align: center;
}