@import "variables";

#slides {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
  height: 100%;
  min-height: $main-box-height;
}

#slides.active-0 .slide {
  transform: translateX(0);
}

#slides:not(.active-0) #slide-title {
  height: $main-box-height;
}

#slides.active-1 .slide {
  transform: translateX(-100%);
}

#slides:not(.active-1) #slide-specs-2 {
  height: $main-box-height;
}

#slides.active-2 .slide {
  transform: translateX(-200%);
}

#slides:not(.active-2) #slide-intro {
  height: $main-box-height;
}

#slides.active-3 .slide {
  transform: translateX(-300%);
}

#slides:not(.active-3) #slide-video-1 {
  height: $main-box-height;
}

#slides.active-4 .slide {
  transform: translateX(-400%);
}

#slides:not(.active-4) #slide-intro-text {
  height: $main-box-height;
}

#slides.active-5 .slide {
  transform: translateX(-500%);
}

#slides:not(.active-5) #slide-video-2 {
  height: $main-box-height;
}

#slides.active-6 .slide {
  transform: translateX(-600%);
}

#slides:not(.active-6) #slide-photo-2 {
  height: $main-box-height;
}

#slides.active-7 .slide {
  transform: translateX(-700%);
}

#slides:not(.active-7) #slide-concept {
  height: $main-box-height;
}

#slides.active-8 .slide {
  transform: translateX(-800%);
}

#slides:not(.active-8) #slide-video-3 {
  height: $main-box-height;
}

#slides.active-9 .slide {
  transform: translateX(-900%);
}

#slides:not(.active-9) #slide-ai-modules {
  height: $main-box-height;
}

#slides.active-10 .slide {
  transform: translateX(-1000%);
}

#slides:not(.active-10) #slide-specs-1 {
  height: $main-box-height;
}

#slides.active-11 .slide {
  transform: translateX(-1100%);
}

#slides:not(.active-11) #slide-centre-pompidou {
  height: $main-box-height;
}

#slides.active-12 .slide {
  transform: translateX(-1200%);
}

#slides:not(.active-12) #slide-photo-3 {
  height: $main-box-height;
}

#slides.active-13 .slide {
  transform: translateX(-1300%);
}

#slides:not(.active-13) #slide-photo-1 {
  height: $main-box-height;
}

#slides.active-14 .slide {
  transform: translateX(-1400%);
}

#slides:not(.active-14) #slide-artist {
  height: $main-box-height;
}

#slides.active-15 .slide {
  transform: translateX(-1500%);
}

#slides:not(.active-15) #slide-collaboration-contact {
  height: $main-box-height;
}

.slides-navigation-button {
  position: absolute;
  top: 330px;
  z-index: 100;
  cursor: pointer;
}

#slides-next {
  right: 20px;
}

#slides-prev {
  left: 20px;
}

#slides .slide {
  flex-shrink: 0;
  align-items: flex-start;
  width: 100%;
  transition: transform 1s;
  overflow: hidden;
  position: relative;
}

#slides .slide.black {
  background-color: #000000;
}

.slide-content {
  padding: 0 60px;
  min-height: $main-box-height;
}

@import 'slides/specs-1';
@import 'slides/specs-2';
@import 'slides/intro';
@import 'slides/title';
@import 'slides/intro-text';
@import 'slides/video-1';
@import 'slides/video-2';
@import 'slides/video-3';
@import 'slides/photo-1';
@import 'slides/photo-2';
@import 'slides/photo-3';
@import 'slides/concept';
@import 'slides/ai-modules';
@import 'slides/artist';

@import 'slides/centre-pompidou';
@import 'slides/collaboration-contact';
