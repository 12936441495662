@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

@import "variables";

@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../node_modules/bulma/sass/base/_all.sass";
@import "../../node_modules/bulma/sass/grid/_all.sass";

body {
  margin-top: 10px;
  font-size: 18px;
  padding: 0 10px;
}

#main-box {
  border: 1px solid #707070;
  max-width: $main-box-width;
  min-height: $main-box-height;
  width: 100%;
  overflow: hidden;
  margin: auto;
  position: relative;
}

#title-box {
  max-width: 950px;
  margin: auto;
}

#title-box a {
  color: #CE00FF;
  font-size: 22px;
}

#title-box .column {
  padding-left: 0;
  padding-right: 0;
}

h1 {
  max-width: $main-box-width;
  font-size: 22px;
  margin: 0 auto 10px auto;
  cursor: pointer;
}

.rounded-button {
  line-height: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 15px;
  padding: 0 15px;
  border: 1px solid white;
}

.underline {
  text-decoration: underline;
}

@import "slides";
@import "menu";