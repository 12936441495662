@import "../variables";

#slide-intro {
  text-align: center;
}

#slide-intro p {
  padding: 0 60px;
}

#slide-intro p .smaller {
  font-size: 15px;
}