@import "../variables";

#slide-artist {
  padding-top: 40px;
  padding-bottom: 140px
}

#slide-artist.slide-content {
  padding-left: 0;
  padding-right: 0;
}

#slide-artist img {
  width: 100%;
}

#slide-artist p {
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
}

#slide-artist h2 {
  padding-left: 60px;
  padding-right: 60px;
  font-size: 30px;
  color: #CE00FF;
}