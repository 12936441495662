@import "../variables";

#slide-specs-1 {
}

#slide-specs-1.slide-content {
  padding: 0 0 80px 0;
  font-size: 13px;
}

#slide-specs-1 .columns {
  padding: 15px 30px;
}