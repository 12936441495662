@import "../variables";

#slide-concept {
  padding-top: 40px;
  padding-bottom: 140px
}

#slide-concept p {
  text-align: justify;
}

#slide-concept p.smaller {
  font-size: 15px;
}

#slide-concept h2 {
  font-size: 60px;
  color: #CE00FF;
  text-transform: uppercase;
  text-align: center;
}