@import "../variables";

#slide-video-3 {
  text-align: center;
}

#slide-video-3 p {
  font-size: 15px;
  padding-left: 40px;
  padding-right: 40px;
}

#slide-video-3.slide-content {
  padding: 0;
}