@import "../variables";

#slide-collaboration-contact {
  padding-top: 40px;
  padding-bottom: 140px
}

#slide-collaboration-contact p {
  text-align: justify;
}

#slide-collaboration-contact p .smaller {
  font-size: 15px;
}

#slide-collaboration-contact h2 {
  font-size: 30px;
  color: #CE00FF;
}