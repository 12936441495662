@import "variables";

#menu {
  font-size: 13px;
  line-height: 25px;
  position: absolute;
  bottom: 30px;
  display: flex;
  left: 30px;
  right: 30px;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}

#menu a {
  text-align: center;
  white-space: nowrap;
  padding: 0 15px;
  cursor: pointer;
}

#menu a:hover {
  text-decoration: underline;
}

#menu a.active {
  font-weight: bold;
}
